{
  "collapse": "Összecsukás",
  "columnResizer": "Oszlopátméretező",
  "drag": "Húzás",
  "expand": "Kibontás",
  "loading": "Betöltés folyamatban…",
  "loadingMore": "Továbbiak betöltése folyamatban…",
  "resizeColumn": "Oszlop átméretezése",
  "sortAscending": "Növekvő rendezés",
  "sortDescending": "Csökkenő rendezés"
}
