{
  "collapse": "Comprimi",
  "columnResizer": "Ridimensionamento colonne",
  "drag": "Trascina",
  "expand": "Espandi",
  "loading": "Caricamento...",
  "loadingMore": "Caricamento altri...",
  "resizeColumn": "Ridimensiona colonna",
  "sortAscending": "Ordinamento crescente",
  "sortDescending": "Ordinamento decrescente"
}
