{
  "collapse": "Sažmi",
  "columnResizer": "Alat za promenu veličine kolone",
  "drag": "Prevuci",
  "expand": "Proširi",
  "loading": "Učitavam...",
  "loadingMore": "Učitavam još...",
  "resizeColumn": "Promeni veličinu kolone",
  "sortAscending": "Sortiraj po rastućem redosledu",
  "sortDescending": "Sortiraj po opadajućem redosledu"
}
