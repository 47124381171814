{
  "collapse": "Ahenda",
  "columnResizer": "Veeru suuruse muutja",
  "drag": "Lohista",
  "expand": "Laienda",
  "loading": "Laadimine...",
  "loadingMore": "Laadi rohkem...",
  "resizeColumn": "Muuda veeru suurust",
  "sortAscending": "Sordi kasvavalt",
  "sortDescending": "Sordi kahanevalt"
}
