{
  "collapse": "Zmenšit",
  "columnResizer": "Změna velikosti sloupce",
  "drag": "Přetáhnout",
  "expand": "Roztáhnout",
  "loading": "Načítání...",
  "loadingMore": "Načítání dalších...",
  "resizeColumn": "Změnit velikost sloupce",
  "sortAscending": "Seřadit vzestupně",
  "sortDescending": "Seřadit sestupně"
}
