{
  "collapse": "Pienennä",
  "columnResizer": "Sarakekoon muuttaja",
  "drag": "Vedä",
  "expand": "Laajenna",
  "loading": "Ladataan…",
  "loadingMore": "Ladataan lisää…",
  "resizeColumn": "Muuta sarakkeen kokoa",
  "sortAscending": "Lajittelujärjestys: nouseva",
  "sortDescending": "Lajittelujärjestys: laskeva"
}
