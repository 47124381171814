{
  "collapse": "Sutraukti",
  "columnResizer": "Stulpelio dydžio keitiklis",
  "drag": "Vilkti",
  "expand": "Išskleisti",
  "loading": "Įkeliama...",
  "loadingMore": "Įkeliama daugiau...",
  "resizeColumn": "Keisti stulpelio dydį",
  "sortAscending": "Rikiuoti didėjimo tvarka",
  "sortDescending": "Rikiuoti mažėjimo tvarka"
}
