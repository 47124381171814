{
  "collapse": "Zbaliť",
  "columnResizer": "Nástroj na zmenu veľkosti stĺpcov",
  "drag": "Presunúť",
  "expand": "Rozbaliť",
  "loading": "Načítava sa...",
  "loadingMore": "Načítava sa viac...",
  "resizeColumn": "Zmeniť veľkosť stĺpca",
  "sortAscending": "Zoradiť vzostupne",
  "sortDescending": "Zoradiť zostupne"
}
