/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

 .react-spectrum-Table {
  --spectrum-table-droptarget-background-color: var(--spectrum-alias-highlight-selected);
  --spectrum-table-dropindicator-border-color: var(--spectrum-dropzone-border-color-selected-hover);
  --spectrum-table-dropindicator-circle-border-color: var(--spectrum-dropindicator-circle-border-color);

  --spectrum-table-dropindicator-circle-size: var(--spectrum-dropindicator-circle-size);
  --spectrum-table-dropindicator-border-size: var(--spectrum-dropindicator-border-size);

  --spectrum-table-dropzone-border-color-selected-hover: var(--spectrum-dropzone-border-color-selected-hover);

  --spectrum-table-item-draghandle-border-color-key-focus: var(--spectrum-table-item-border-color-key-focus);
 }

.react-spectrum-Table {
  user-select: none;
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
}

.react-spectrum-Table-cell {
  display: flex;
  align-items: center;
}

.react-spectrum-Table-cellWrapper > .react-spectrum-Table-cell {
  height: 100%;
}

.react-spectrum-Table-cell--alignStart {
  text-align: start;
  justify-content: flex-start;
}

.react-spectrum-Table-cell--alignCenter {
  text-align: center;
  justify-content: center;
}

.react-spectrum-Table-cell--alignEnd {
  text-align: end;
  justify-content: flex-end;
}

.react-spectrum-Table-centeredWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.react-spectrum-Table-row-dragPreview {
  --spectrum-table-row-badge-text-color: var(--spectrum-global-color-static-white);
  --spectrum-table-row-badge-background-color: var(--spectrum-global-color-blue-400);

  width: var(--spectrum-global-dimension-size-2400);
  border: 1px solid var(--spectrum-dropzone-border-color-selected-hover);
  border-radius: var(--spectrum-alias-border-radius-regular);
  background-color: var(--spectrum-table-background-color);

  /* forced-color-adjust: none, so that background-color style will render */
  forced-color-adjust: none;

  &::after {
    box-shadow: none;
  }

  .react-spectrum-Table-cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.react-spectrum-Table-row-dragPreview--multiple {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 4px;
      inset-inline-start: 4px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--spectrum-dropzone-border-color-selected-hover);
      border-radius: var(--spectrum-alias-border-radius-regular);
      background-color: var(--spectrum-table-background-color);

      /* forced-color-adjust: none, so that box-shadow style will render */
      forced-color-adjust: none;
    }
  }

  .react-spectrum-Table-row-badge {
    color: var(--spectrum-table-row-badge-text-color);
    background: var(--spectrum-table-row-badge-background-color);
    padding: 0 var(--spectrum-global-dimension-size-100);
    border-radius: var(--spectrum-alias-border-radius-regular);
    margin-inline-end: var(--spectrum-global-dimension-size-100);
    margin-top: auto;
    margin-bottom: auto;
    height: fit-content;
  }
}

.react-spectrum-Table-body {
  &.react-spectrum-Table-body--dropTarget {
    border-color: var(--spectrum-table-dropindicator-border-color);
    background-color: var(--spectrum-alias-highlight-selected);
    box-shadow: inset 0 0 0 1px var(--spectrum-table-cell-border-color-key-focus);

    /* forced-color-adjust: none, so that box-shadow style will render */
    forced-color-adjust: none;
  }
}

.react-spectrum-Table-InsertionIndicator {
  z-index: 3;
  width: calc(100% - (2 * var(--spectrum-table-dropindicator-circle-size)));
  inset-inline-start: var(--spectrum-table-dropindicator-circle-size);
  position: absolute;
  outline: none;

  &.react-spectrum-Table-InsertionIndicator--dropTarget {
    background: var(--spectrum-table-dropindicator-border-color);
    border-bottom: 2px solid var(--spectrum-table-dropindicator-border-color);

    /* forced-color-adjust: none, so that background color
    will render border for insertion indicator. */
    forced-color-adjust: none;

    &:before {
      left: calc(var(--spectrum-table-dropindicator-circle-size) * -1);
    }

    &:after {
      right: calc(var(--spectrum-table-dropindicator-circle-size) * -1);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: calc(var(--spectrum-table-dropindicator-circle-size) * -1 / 2 + var(--spectrum-table-dropindicator-border-size) / 2);
      width: var(--spectrum-table-dropindicator-circle-size);
      height: var(--spectrum-table-dropindicator-circle-size);
      border-radius: 50%;
      border: var(--spectrum-table-dropindicator-border-size) solid;
      box-sizing: border-box;
      border-color: var(--spectrum-table-dropindicator-circle-border-color);
      background-color: var(--spectrum-table-background-color);
      z-index: 5;

      /* forced-color-adjust: none, so that box-shadow and background-color styles will render */
      forced-color-adjust: none;
    }
  }
}

.react-spectrum-Table-headCell {
  &.react-spectrum-Table-dragButtonHeadCell {
    padding: 0;
    height: inherit;
  }
}

.react-spectrum-Table-cell {
  &.react-spectrum-Table-dragButtonCell {
    padding: var(--spectrum-global-dimension-size-40);

    .react-spectrum-Table-dragButton {
      border-radius: var(--spectrum-alias-border-radius-regular);
      padding: var(--spectrum-global-dimension-size-10);

      &:focus-visible {
        box-shadow: inset 0 0 0 2px var(--spectrum-table-cell-border-color-key-focus);
        outline: none;

        /* forced-color-adjust: none, so that box-shadow style will render */
        forced-color-adjust: none;
      }

      svg {
        margin: auto 0;
        display: block;
        padding: var(--spectrum-global-dimension-size-40);
      }
    }
  }
}

.react-spectrum-Table-row {
  &.react-spectrum-Table-row--dropTarget {
    border: 1px var(--spectrum-table-dropindicator-border-color);
    box-shadow: inset 2px 0 0 0 var(--spectrum-table-dropindicator-border-color),
      inset -2px 0 0 0 var(--spectrum-table-dropindicator-border-color),
      inset 0 -3px 0 0 var(--spectrum-table-dropindicator-border-color),
      inset 0 2px 0 0 var(--spectrum-table-dropindicator-border-color);

    /* forced-color-adjust: none, so that box-shadow style will render */
    forced-color-adjust: none;
  }

  .react-spectrum-Table-cellWrapper.react-spectrum-Table-cellWrapper--dropTarget {
    background-color: var(--spectrum-table-droptarget-background-color);
  }
}

@media (forced-colors: active) {
  .react-spectrum-Table,
  .react-spectrum-Table-row {
    --spectrum-table-droptarget-background-color: var(--spectrum-alias-global-color-transparent);
    --spectrum-table-dropindicator-border-color: Highlight;
    --spectrum-dropzone-border-color-selected-hover: Highlight;
    --spectrum-table-dropindicator-circle-border-color: Highlight;
    --spectrum-table-dropzone-border-color-selected-hover: Highlight;
    --spectrum-table-row-badge-text-color: HighlightText;
    --spectrum-table-row-badge-background-color: Highlight;
  }
}
