{
  "collapse": "Dölj",
  "columnResizer": "Ändra storlek på kolumn",
  "drag": "Dra",
  "expand": "Expandera",
  "loading": "Läser in...",
  "loadingMore": "Läser in mer...",
  "resizeColumn": "Ändra storlek på kolumn",
  "sortAscending": "Sortera i stigande ordning",
  "sortDescending": "Sortera i fallande ordning"
}
