{
  "collapse": "Sakļaut",
  "columnResizer": "Kolonnas izmēru mainītājs",
  "drag": "Vilkšana",
  "expand": "Izvērst",
  "loading": "Notiek ielāde...",
  "loadingMore": "Tiek ielādēts vēl...",
  "resizeColumn": "Mainīt kolonnas lielumu",
  "sortAscending": "Kārtot augošā secībā",
  "sortDescending": "Kārtot dilstošā secībā"
}
