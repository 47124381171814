{
  "collapse": "折りたたむ",
  "columnResizer": "列リサイザー",
  "drag": "ドラッグ",
  "expand": "展開",
  "loading": "読み込み中...",
  "loadingMore": "さらに読み込み中...",
  "resizeColumn": "列幅を変更",
  "sortAscending": "昇順に並べ替え",
  "sortDescending": "降順に並べ替え"
}
