{
  "collapse": "Згорнути",
  "columnResizer": "Засіб змінення розміру стовпця",
  "drag": "Перетягнути",
  "expand": "Розгорнути",
  "loading": "Завантаження…",
  "loadingMore": "Завантаження інших об’єктів...",
  "resizeColumn": "Змінити розмір стовпця",
  "sortAscending": "Сортувати за зростанням",
  "sortDescending": "Сортувати за спаданням"
}
