{
  "collapse": "Sažmi",
  "columnResizer": "Alat za promjenu veličine stupca",
  "drag": "Povucite",
  "expand": "Proširi",
  "loading": "Učitavam...",
  "loadingMore": "Učitavam još...",
  "resizeColumn": "Promijeni veličinu stupca",
  "sortAscending": "Sortiraj uzlazno",
  "sortDescending": "Sortiraj silazno"
}
