{
  "collapse": "Свиване",
  "columnResizer": "Преоразмеряване на колони",
  "drag": "Плъзнете",
  "expand": "Разширяване",
  "loading": "Зареждане...",
  "loadingMore": "Зареждане на още...",
  "resizeColumn": "Преоразмеряване на колона",
  "sortAscending": "Възходящо сортиране",
  "sortDescending": "Низходящо сортиране "
}
