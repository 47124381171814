{
  "collapse": "Свернуть",
  "columnResizer": "Средство изменения размера столбцов",
  "drag": "Перетаскивание",
  "expand": "Развернуть",
  "loading": "Загрузка...",
  "loadingMore": "Дополнительная загрузка...",
  "resizeColumn": "Изменить размер столбца",
  "sortAscending": "Сортировать по возрастанию",
  "sortDescending": "Сортировать по убыванию"
}
