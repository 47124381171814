{
  "loading": "Loading…",
  "loadingMore": "Loading more…",
  "sortAscending": "Sort Ascending",
  "sortDescending": "Sort Descending",
  "resizeColumn": "Resize column",
  "columnResizer": "Column resizer",
  "drag": "Drag",
  "expand": "Expand",
  "collapse": "Collapse"
}
