{
  "collapse": "Σύμπτυξη",
  "columnResizer": "Αλλαγή μεγέθους στήλης",
  "drag": "Μεταφορά",
  "expand": "Ανάπτυξη",
  "loading": "Φόρτωση...",
  "loadingMore": "Φόρτωση περισσότερων...",
  "resizeColumn": "Αλλαγή μεγέθους στήλης",
  "sortAscending": "Ταξινόμηση κατά αύξουσα σειρά",
  "sortDescending": "Ταξινόμηση κατά φθίνουσα σειρά"
}
