{
  "collapse": "Reduzieren",
  "columnResizer": "Spaltenanpassung",
  "drag": "Ziehen",
  "expand": "Erweitern",
  "loading": "Laden...",
  "loadingMore": "Mehr laden ...",
  "resizeColumn": "Spaltengröße ändern",
  "sortAscending": "Aufsteigend sortieren",
  "sortDescending": "Absteigend sortieren"
}
