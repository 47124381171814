{
  "collapse": "طي",
  "columnResizer": "أداة تغيير حجم العمود",
  "drag": "سحب",
  "expand": "مد",
  "loading": "جارٍ التحميل...",
  "loadingMore": "جارٍ تحميل المزيد...",
  "resizeColumn": "تغيير حجم العمود",
  "sortAscending": "فرز بترتيب تصاعدي",
  "sortDescending": "فرز بترتيب تنازلي"
}
