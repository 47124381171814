{
  "collapse": "Restrângeți",
  "columnResizer": "Instrument redimensionare coloane",
  "drag": "Trageți",
  "expand": "Extindeți",
  "loading": "Se încarcă...",
  "loadingMore": "Se încarcă mai multe...",
  "resizeColumn": "Redimensionați coloana",
  "sortAscending": "Sortați crescător",
  "sortDescending": "Sortați descrescător"
}
