{
  "collapse": "Zwiń",
  "columnResizer": "Narzędzie zmiany rozmiaru kolumny",
  "drag": "Przeciągnij",
  "expand": "Rozwiń",
  "loading": "Ładowanie...",
  "loadingMore": "Wczytywanie większej liczby...",
  "resizeColumn": "Zmień rozmiar kolumny",
  "sortAscending": "Sortuj rosnąco",
  "sortDescending": "Sortuj malejąco"
}
