{
  "collapse": "כווץ",
  "columnResizer": "שינוי גודל עמודה",
  "drag": "גרור",
  "expand": "הרחב",
  "loading": "טוען...",
  "loadingMore": "טוען עוד...",
  "resizeColumn": "שנה את גודל העמודה",
  "sortAscending": "מיין בסדר עולה",
  "sortDescending": "מיין בסדר יורד"
}
