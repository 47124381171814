{
  "collapse": "Réduire",
  "columnResizer": "Redimensionnement de colonne",
  "drag": "Faire glisser",
  "expand": "Développer",
  "loading": "Chargement...",
  "loadingMore": "Chargement supplémentaire...",
  "resizeColumn": "Redimensionner la colonne",
  "sortAscending": "Trier par ordre croissant",
  "sortDescending": "Trier par ordre décroissant"
}
