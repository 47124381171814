{
  "collapse": "Contraer",
  "columnResizer": "Redimensionador de columnas",
  "drag": "Arrastrar",
  "expand": "Ampliar",
  "loading": "Cargando…",
  "loadingMore": "Cargando más…",
  "resizeColumn": "Cambiar el tamaño de la columna",
  "sortAscending": "Orden de subida",
  "sortDescending": "Orden de bajada"
}
