{
  "collapse": "Daralt",
  "columnResizer": "Yeniden sütun boyutlandırıcı",
  "drag": "Sürükle",
  "expand": "Genişlet",
  "loading": "Yükleniyor...",
  "loadingMore": "Daha fazla yükleniyor...",
  "resizeColumn": "Sütunu yeniden boyutlandır",
  "sortAscending": "Artan Sıralama",
  "sortDescending": "Azalan Sıralama"
}
