{
  "collapse": "Skjul",
  "columnResizer": "Kolonneændring",
  "drag": "Træk",
  "expand": "Udvid",
  "loading": "Indlæser ...",
  "loadingMore": "Indlæser flere ...",
  "resizeColumn": "Tilpas størrelse på kolonne",
  "sortAscending": "Sorter stigende",
  "sortDescending": "Sorter faldende"
}
