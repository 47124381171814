{
  "collapse": "Skjul",
  "columnResizer": "Størrelsesendring av kolonne",
  "drag": "Dra",
  "expand": "Utvid",
  "loading": "Laster inn ...",
  "loadingMore": "Laster inn flere ...",
  "resizeColumn": "Endre størrelse på kolonne",
  "sortAscending": "Sorter stigende",
  "sortDescending": "Sorter synkende"
}
